/**
 * @generated SignedSource<<c5a281fbe0da9591804c8cdc8589a3b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersStyleArticleListQuery$data = {
  readonly beauty: {
    readonly items: {
      readonly __typename: "QueueItemConnection";
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"itemStoryCardCarouselWidgetDisplayItemArticle" | "storyStyleCardArticle">;
        };
        readonly " $fragmentSpreads": FragmentRefs<"storyStyleLargeLayoutQueueItemsEdge">;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"helpersIsPublishedWithin24HoursQueueItemConnection">;
    } | null | undefined;
  } | null | undefined;
  readonly fashion: {
    readonly items: {
      readonly __typename: "QueueItemConnection";
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"itemStoryCardCarouselWidgetDisplayItemArticle" | "storyStyleCardArticle">;
        };
        readonly " $fragmentSpreads": FragmentRefs<"storyStyleLargeLayoutQueueItemsEdge">;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"helpersIsPublishedWithin24HoursQueueItemConnection">;
    } | null | undefined;
  } | null | undefined;
  readonly lifestyle: {
    readonly items: {
      readonly __typename: "QueueItemConnection";
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"itemStoryCardCarouselWidgetDisplayItemArticle" | "storyStyleCardArticle">;
        };
        readonly " $fragmentSpreads": FragmentRefs<"storyStyleLargeLayoutQueueItemsEdge">;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"helpersIsPublishedWithin24HoursQueueItemConnection">;
    } | null | undefined;
  } | null | undefined;
  readonly luxury: {
    readonly items: {
      readonly __typename: "QueueItemConnection";
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"itemStoryCardCarouselWidgetDisplayItemArticle" | "storyStyleCardArticle">;
        };
        readonly " $fragmentSpreads": FragmentRefs<"storyStyleLargeLayoutQueueItemsEdge">;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"helpersIsPublishedWithin24HoursQueueItemConnection">;
    } | null | undefined;
  } | null | undefined;
  readonly people: {
    readonly items: {
      readonly __typename: "QueueItemConnection";
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"itemStoryCardCarouselWidgetDisplayItemArticle" | "storyStyleCardArticle">;
        };
        readonly " $fragmentSpreads": FragmentRefs<"storyStyleLargeLayoutQueueItemsEdge">;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"helpersIsPublishedWithin24HoursQueueItemConnection">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "helpersStyleArticleListQuery";
};
export type helpersStyleArticleListQuery$key = {
  readonly " $data"?: helpersStyleArticleListQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersStyleArticleListQuery">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersStyleArticleListQuery"
};

(node as any).hash = "0a2f15a33d9f37e410d13863682fd5d1";

export default node;
