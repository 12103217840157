import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { ContentPlaceholder } from "scmp-app/components/content/content-placeholder";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { staticPathname as storyStylePageStaticPathname } from "scmp-app/pages/story-style";
import type { itemStoryCardCarouselWidgetDisplayItemArticle$key } from "scmp-app/queries/__generated__/itemStoryCardCarouselWidgetDisplayItemArticle.graphql";

import { Container, RedDotIcon, SectionName, StyledBaseImage, StyledBaseLink } from "./styles";

type Props = {
  hasRedDot: boolean;
  pathname: string;
  reference: itemStoryCardCarouselWidgetDisplayItemArticle$key;
  sectionName: string;
};

export const StoryCardCarouselWidgetDisplayItem: FunctionComponent<Props> = ({
  hasRedDot = false,
  pathname,
  reference: reference_,
  sectionName,
}) => {
  const article = useFragment(
    graphql`
      fragment itemStoryCardCarouselWidgetDisplayItemArticle on Article {
        images(filter: { type: { exclude: null } }) {
          alt
          isSlideshow
          style(filter: { style: "134w_portrait" }) {
            url
          }
        }
        ...entityLink
      }
    `,
    reference_,
  );

  const styleStoryCardPathname = `${storyStylePageStaticPathname}/${pathname}`;

  const handleRenderImage = () => {
    const filteredImage = (article?.images ?? []).find(image => image?.isSlideshow);
    const alt = filteredImage?.alt ?? "";
    const source = filteredImage?.style?.url ?? "";

    if (!source.trim()) {
      return <ContentPlaceholder />;
    }

    return (
      <StyledBaseImage
        $aspectRatio="2 / 3"
        $height="auto"
        $objectFit="cover"
        $width="100%"
        alt={alt}
        src={source}
      />
    );
  };

  const handleClick = () => {
    const url = new URL(`${window.location.origin}${styleStoryCardPathname}`);
    if (!url) return;

    url.searchParams.set("origin", window.location.href);

    sendGA4Tracking({
      action: "click",
      category: "storycard",
      customized_parameters: {
        cta_type: "carousel",
        destination_url: url.toString(),
      },
      subcategory: "",
    });
  };

  return (
    <Container>
      <StyledBaseLink
        pathname={styleStoryCardPathname}
        query={{
          origin: window.location.href,
        }}
        onClick={handleClick}
      >
        {handleRenderImage()}
      </StyledBaseLink>
      {hasRedDot && <RedDotIcon />}
      <SectionName>{sectionName}</SectionName>
    </Container>
  );
};

StoryCardCarouselWidgetDisplayItem.displayName = "StoryCardCarouselWidgetDisplayItem";
